import "../sass/app.scss";
import $ from 'jquery';
import bootstrap from './bootstrap.js';

// require('./bootstrap')
// require('./utilities/hamburgerMenu')
// require('./utilities/filter')

$(document).ready(function () {
  // Hover dropdown
  $('body').on('mouseenter mouseleave', '.dropdown', function (e) {
    let dropdown = $(e.target).closest('.dropdown')
    let menu = $('.dropdown-menu', dropdown)

    dropdown.addClass('show')
    menu.addClass('show')

    setTimeout(function () {
      dropdown[dropdown.is(':hover') ? 'addClass' : 'removeClass']('show')
      menu[dropdown.is(':hover') ? 'addClass' : 'removeClass']('show')
    }, 300)
  })

  $('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: '<i class="fa fa-angle-left">&nbsp;</i>',
    nextArrow: '<i class="fa fa-angle-right">&nbsp;</i>',
    autoplaySpeed: 3000,
    speed: 600,
    asNavFor: '.slider-nav'
  })

  $('.slider-nav').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 1333,
    speed: 666,
    arrows: false,
    dots: true,
    asNavFor: '.slider-for'
  })

  $('.archive-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<i class="fa fa-angle-left">&nbsp;</i>',
    nextArrow: '<i class="fa fa-angle-right">&nbsp;</i>',
    dots: true
  })
})
