/**
 * Bootstrap the application
 */

window.Popper = require('popper.js').default // eslint-disable-line
let $ = window.$ = window.jQuery // eslint-disable-line

require('./skip-link-focus-fix')
require('./vendor/slick.min.js')
require('bootstrap')
